footer.content-info {
	background: #184887;
	color: #fff;
	text-align: center;
	padding: 2.5rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	letter-spacing: 0.1em;

	a {
		&:link, &:visited {
			color: #fff; }

		&:hover, &:active, &:focus {
			color: $brand-secondary; } } }

.footer-items {
	margin: 0;
	padding: 0;

	li:first-child {
		border: 0; }

	li {
		display: inline-block;
		margin: 0 0.25rem;
		padding: 0 0 0 1rem;
		border-left: solid 2px; }

	@media screen and (max-width: 1200px) {
		font-size: 0.9em; }

	@media screen and (max-width: 992px) {
		li:nth-child(odd) {
			border: 0; } }

	@media screen and (max-width: 992px) {
		li {
			border: 0;
			display: block; } } }
