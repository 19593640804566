body {
	font: {
		family: "canada-type-gibson",sans-serif;
		size: 1.6rem; }
	background: {
		image: url(../../assets/images/sky-bg.jpg);
		position: center;
		size: cover;
		attachment: fixed; }
	color: $brand-primary;
	max-width: 100vw;
	overflow-x: hidden; }

#svg-sprites {
	display: none; }

a {
	transition: 0.2s all ease-in-out; }

h1 {
	font: {
		size: 2.4rem;
		weight: 600; }
	margin: 1rem 0 2rem; }

h2 {
	font: {
		size: 2.4rem;
		weight: 600; }
	text-transform: uppercase;
	margin: 1rem 0; }


.bigBtn {
	display: block;
	width: 100%;
	text-align: center;
	color: #fff;
	background: $brand-primary;
	padding: 1rem 1.5rem;
	font-size: 2.4rem;
	font-weight: 600;
	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $brand-secondary; } }

img {
	max-width: 100%;
	height: auto; }


.row--flex {
	display: flex;

	@media screen and (max-width: 768px) {
		display: block; } }
