body.fl-builder {
	.wrap.container {
		width: 100%;
		max-width: none;
		padding: 0;

		.content.row {
			margin: 0;

			.main {
				padding: 0; } } } }

.smaller-row {
	.fl-row-fixed-width {
		max-width: 1000px; } }


.parking-table {
	width: 100%;

	th {
		text-align: center;
		font-weight: 600;
		font-size: 2.4rem;
		padding: 1rem;
		padding-top: 0; }

	tr {
		&.border {
			border-bottom: solid 2px $brand-primary;

			td {
				padding-bottom: 1rem; } }

		&.padding {
			td {
				padding-top: 1rem; } } }

	td {
		padding: 0.25rem 1rem;

		&.title {
			font-size: 2rem;
			font-weight: 600; } } }

.mobile-tagline {
	line-height: 5rem; }

// Deals

.deal {
	background-color: #fff;
	padding: 1rem;
	border-top-right-radius: 1.5rem;

	&-container {
		>.row {
			display: flex;
			flex-wrap: wrap;

			@media screen and (max-width: 768px) {
				display: block; } } }

	&-wrap {
		margin-bottom: 2rem;
		padding: 1rem;

		@media screen and (max-width: 768px) {
			padding: 0; } }

	.right {
		text-align: right;
		padding-left: 0; }

	.photo {
		width: 100%;
		height: 7.8rem;
		background-size: cover;
		background-position: center;
		background-color: $brand-secondary; }

	button.book {
		display: block;
		width: 100%;
		margin-top: 1rem;
		border: 0;
		background: $brand-primary;
		color: #fff;
		transition: 0.2s all ease-in-out;
		text-transform: uppercase;
		font-size: 1.5rem;
		line-height: 1;
		padding: 0.5em;

		@media screen and (max-width: 768px) {
			font-size: 1.1rem; } }

	&:hover {
		button.book {
			background: $brand-secondary; } }

	.title {
			font-size: 2.4rem;
			font-weight: 600; }

	.price {
		font-size: 3rem;
		font-weight: 600;

		sup {
			top: -.5em;
			font-size: 0.6em; } }

	.round-trip, .business {
		display: block;
		color: $brand-secondary;
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: 600;

		@media screen and (max-width: 768px) {
			font-size: 1rem; } }

	.excerpt {
		font-size: 1.3rem;
		margin-top: 1rem; }

	.logo {
		max-width: 10rem;
		max-height: 2rem; }

	&.local-deal {
		.title {
			font-size: 2rem; } }

	&-attractions {
		background: $brand-secondary;
		color: $brand-primary;
		font-size: 1.2rem;
		border-bottom-right-radius: 1.5rem;
		text-transform: uppercase;
		line-height: 1;
		font-weight: 600;
		position: relative;

		.view {
			padding: .75rem 1.5rem;
			cursor: pointer;

			&::selection {
				background: none; }

			&:after {
				content: '\25be';
				float: right; } }

		&.open {
			border-bottom-right-radius: 0;

			.view:after {
				content: '\25b4'; } }

		.attraction-list {
			line-height: 1.5;
			list-style: none;
			position: absolute;
			left: 0;
			right: 0;
			padding: 1rem 1.5rem;
			background: $brand-secondary;
			border-bottom-right-radius: 1.5rem;
			border-top: dashed 2px $brand-primary;
			z-index: 50;
			box-shadow: 0px 1rem 1rem 0px rgba(0, 0, 0, 0.5);
			text-align: left;
			display: none;
			width: 100%;
			color: #333;

			a {
				display: block;

				&:link, &:visited {
					color: $brand-primary; }

				&:hover, &:active, &:focus {
					color: lighten($brand-primary, 10%); } } } } }

// Browser fixes
body.safari {
	.deal-wrap {
		width: 33%;

		@media screen and (max-width: 992px) {
			width: 49%; }

		@media screen and (max-width: 768px) {
			width: 100%; } } }

// Single deal
.wrap.container {
	background: #FFF;
	padding: 5rem 1.5rem;
	min-height: 80.8vh; }

.fl-builder {
	.wrap.container {
		background: none;
		padding: 0 1.5rem;
		min-height: 0; } }

article.local_deal {
	border: dashed;
	padding: 2rem 0;

	.deal-photo {
		background: {
			size: cover;
			position: center; }
		margin-left: 1.5rem;
		padding-bottom: 20%;

		@media screen and (max-width: 768px) {
			padding-bottom: 50%;
			margin: 1.5rem; } }

	.deal-info {
		a:link, a:visited {
			color: $brand-secondary; } }

	h1 {
		margin-top: 0; }

	.business {
		color: $brand-secondary; }

	.fine-print {
		font-size: 0.75em;
		margin-top: 1.5rem;
		font-style: italic;

		p {
			margin-bottom: 0; } } }

// Deal archives
.archive {
	.wrap.container {
		background: none; } }

// Homepage banner
.g-single {
	text-align: center; }
