// Glyphicons font path
$icon-font-path:     "../fonts/";

// Grid settings
$main-sm-columns:    12;
$sidebar-sm-columns: 4;

// Colors
$brand-primary:      #184887;
$brand-secondary:    #fdb515;
