.btn {
	display: inline-block;
	background: $brand-secondary;
	color: #fff;
	padding: 0.43em 1em;
	border: 0;
	border-radius: 0;
	text-transform: uppercase;

	&:hover {
		background: $brand-primary;
		color: #FFF;
		text-decoration: none; } }

.print-btn {
	@extend .btn;
	margin: 3rem auto;
	border-radius: 3px; }
