.banner {
	background: $brand-secondary;

	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 768px) {
			display: block; } }

	.brand {
		text-transform: lowercase;
		font-weight: 600;
		font-size: 6rem; }

	.brand:hover {
		text-decoration: none; }

	.tagline {
		text-align: center;
		font: {
			size: 3.6rem;
			weight: 600; }

		@media screen and (max-width: 768px) {
			display: none; } }

	.social-media {
		float: right;

		.icon {
			width: 5rem;
			height: 5rem;
			transition: 0.2s all ease-in-out;

			fill: $brand-primary;

			&:hover {
				fill: #FFF; } } }

	@media screen and (max-width: 990px) {
		.brand {
			font-size: 4rem; }

		.tagline {
			font-size: 2.6rem; }

		.social-media .icon {
			width: 4rem;
			height: 4rem; } }

	@media screen and (max-width: 768px) {
		.brand {
			font-size: 3rem; }

		.social-media .icon {
			width: 3rem;
			height: 3rem;

			margin-top: 0.5rem; } } }

.navbar {
	background: $brand-primary;
	color: #fff;
	margin-bottom: 0;
	border-radius: 0;
	min-height: 0;
	font-size: 1.8rem;
	font-weight: 600;
	letter-spacing: 0.01em;

	.nav {
		display: table;
		margin: 0 auto;

		.menu-item {
			display: table-cell;
			padding: 0 2.5rem;
			margin: 0 1em;
			vertical-align: middle;

			a {
				&:link, &:visited {
					color: #fff; }

				&:hover, &:active, &:focus {
					background: none;
					color: $brand-secondary; } }

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 3.2rem;
				background-repeat: no-repeat;
				background-position: center;
				content: ""; }

			&.menu-travel-deals:before {
				background-image: url(../../assets/images/icon-airplane.svg); }

			&.menu-parking-information:before {
				background-image: url(../../assets/images/icon-parking.svg); }

			&.menu-local-deals:before {
				background-image: url(../../assets/images/icon-shopping.svg); }

			&.menu-directions:before {
				background-image: url(../../assets/images/icon-map-marker.svg); } }

		@media screen and (max-width: 768px) {
			display: none;

			.menu-item {
				display: block;
				width: 100%;
				text-align: right; } }

		@media screen and (min-width: 768px) {
			display: table !important; } }

	&-toggle {
		display: none;
		border: solid 2px #fff;
		margin: 1rem 0 1rem auto;
		padding: 0.75rem;
		float: none;

		.icon-bar {
			background: #fff; }

		@media screen and (max-width: 768px) {
			display: block; } }

	&.fixed {
		position: fixed;
		top: -60px;
		left: 0;
		right: 0;
		z-index: 1000;

		&.top {
			top: 0;
			transition: 0.25s all ease-in-out; } } }

.admin-bar {
	.fixed.top {
		top: 32px;

		@media screen and (max-width: 600px) {
			top: 0; } } }
